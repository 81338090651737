.contact {
  width: 100%;
  padding-bottom: 4%;
  margin-top: 5%;
  clear: both;
}

.contactContent {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}

.contactContent h4 {
  font-size: 1.875em;
  padding: 1% 0;
  color: #283344;
  font-weight: 600;
  letter-spacing: 0.04em;
}

.contactContent a {
  color: #283344;
  text-decoration: none;
}

.email {
  display: inline-block;
}

.email:after {
  content: '';
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.email:hover:after {
  width: 100%;
  background: #283344;
}

@media only screen and (max-width:1000px) {
  .contact {
    padding: 4% 0;
  }
}

@media only screen and (max-width:800px) {
  .contactContent {
    font-size: 12px;
    line-height: 1.5;
  }
}
