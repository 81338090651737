.button {
  padding: 5% 3%;
  border: 3px solid #283344;
  letter-spacing: 0.2em;
  text-decoration: none;
  color: #283344;
  text-align: center;
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 4%;
}

.button:hover {
  color: #fff;
  background-color: #283344;
}
