.cards {
  padding: 4% 0;
}

.cards h3 {
  padding: 4% 0;
}

@media only screen and (max-width:1000px) {
  .cardsTitle h3 {
    clear: both;
    padding-top: 4%;
  }
}
