footer {
  background-color: #283344;
  color: #fff;
  padding: 2% 0;
  width: 100%;
  clear: both;
  height: 100px;
}

.footerContent {
  max-width: 960px;
  padding: 2% 0;
  margin: 0 auto;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  color: #fff;
  letter-spacing: .03em;
  font-size: 1em;
}

.credit {
  float: left;
}

@media only screen and (max-width:1000px) {
  .footer-content {
    padding: 3.5%;
  }
}

@media only screen and (max-width:800px) {
  footer {
    height: 130px;
    line-height: 1.3em;
  }
  .footer-content {
    padding: 4%;
    font-size: 14px;
  }
}
