header {
  background: linear-gradient(to bottom, rgba(40, 51, 68, 1) 0%, rgba(63, 76, 107, 0.9) 0%), url(/images/header.jpg);
  background-size: cover;
  background-position: center top;
  width: 100%;
  background-attachment: fixed;
}

.intro {
  max-width: 960px;
  margin: 0 auto;
  padding: 8% 0;
}

.intro h1 {
  font-size: 6em;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 800;
  line-height: 1.5em;
}

.intro h2 {
  font-size: 2em;
  text-align: center;
  color: #fff;
  font-weight: 300;
  text-transform: capitalize;
  letter-spacing: 0.09em;
}

@media only screen and (max-width:800px) {
  .intro h1 {
    font-size: 4em;
    line-height: 1.2;
  }
  .intro h2 {
    font-size: 1.8em;
    padding-top: 3%;
  }
}
