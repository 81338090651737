.notFound {
  padding: 4% 0;
  text-align: center;
}

.notFound p {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.8;
  color: #616569;
  letter-spacing: .03em;
  margin: 10px auto;
  font-size: 1.5em;
  padding: 2% 0;
}

.notFound p a {
  color: #E1CCC9;
  border-bottom: 2px solid;
}

@media only screen and (max-width:800px) {
  .notFound p {
    font-size: 1em;
    line-height: 2;
  }
}
