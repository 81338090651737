.card {
  height: 300px;
}

.textBox {
  float: left;
  text-align: left;
  width: 480px;
  background-color: #fff;
  height: 100%;
}

.textBoxContent {
  padding: 4% 8%;
}

.textBoxContent p {
  line-height: 1.8;
  color: #616569;
  letter-spacing: .03em;
  font-size: 1em;
}

.imageArea {
  width: 480px;
  height: 300px;
  overflow: hidden;
  background-size: cover;
}

.left {
  float: left;
}

.right {
  float: right;
}

@media only screen and (max-width:1000px) {
  .imageArea {
    float: left;
    width: 100%;
    height: auto;
  }
  .textBox {
    width: 100%;
    height: auto;
  }
}
