.social {
  float: right;
}

.social li {
  display: inline;
}

.social li a {
  color: #fff;
  padding: 0.2em;
  font-size: 1.8em;
  font-weight: 600;
  display: inline-block;
}

.social li a:after{
  content: '';
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width .5s ease, background-color .5s ease;
}

.social li a:hover:after {
  width: 100%;
  background: white;
}

@media only screen and (max-width:1000px) {
  .social li a {
    font-size: 1.4em;
  }
}

@media only screen and (max-width:800px) {
  .social {
    float: right;
  }
  .social li a {
    font-size: 1.3em;
  }
}
