.wrapper {
  max-width: 960px;
  margin: 0 auto;
  font-family: proxima-nova, sans-serif;
  -webkit-animation-delay: 4s;
  animation-delay: 2s;
}

@media only screen and (max-width:1000px) {
  .wrapper {
    padding: 0% 4%;
  }
}
