code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  background-color: #f9f9f9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  -o-transition: .5s;
  -ms-transition: .5s;
  -moz-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s;
  text-decoration: none;
}

em {
  font-style: oblique;
}

strong {
  font-style: bold;
}

img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: proxima-nova, sans-serif;
  font-style: normal;
}

h3 {
  text-transform: capitalize;
  font-size: 1.875em;
  padding: 0 0 1% 0;
  color: #001833;
  font-weight: 800;
}
